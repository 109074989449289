<template>
  <div>
    <!-- <DarkModeSwitcher /> -->
    <MobileMenu />
    <!-- <TopBar /> -->
    <div class="flex">
      <!-- BEGIN: Side Menu -->
      <nav class="side-nav relative">
        <!-- BEGIN: Logo -->
        <div class="text-center flex gap-2 items-center">
          <img src="@/assets/images/newLogoSide2.png" alt="" />
          <!-- <h1 class="hidden xl:block">
            <app-i18n
              id="logo"
              class="text-base text-white"
              code="app.title"
            ></app-i18n>
          </h1> -->

          <!-- <span class="hidden xl:block text-white text-lg ml-3">Used Brands</span> -->
        </div>
        <!-- END: Logo -->
        <div class="side-nav__devider my-6"></div>

        <ul>
          <!-- BEGIN: First Child -->
          <template v-for="(menu, menuKey) in formattedMenu">
            <li
              v-if="menu == 'devider'"
              :key="menu + menuKey"
              class="side-nav__devider my-6"
            ></li>
            <li v-else :key="menuKey">
              <SideMenuTooltip
                tag="a"
                :content="menu.title"
                v-if="hasPermissionToRead(menu.permission)"
                :href="
                  menu.subMenu
                    ? 'javascript:;'
                    : router.resolve({ name: menu.pageName }).path
                "
                class="side-menu"
                :class="{
                  'side-menu--active': menu.active,
                  'side-menu--open': menu.activeDropdown
                }"
                @click="linkTo(menu, router, $event)"
              >
                <div class="side-menu__icon">
                  <!-- <span
                    v-if="menu.icon == 'LogsIcon'"
                    class="material-icons-outlined ml-1"
                    :style="darkMode ? 'color: #828d9b' : 'color: #b7b7b7'"
                  > -->
                  <svg
                    v-if="menu.icon == 'LogsIcon'"
                    style="width: 24px; height: 24px; color: #b7b7b7"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M21 11.11V5C21 3.9 20.11 3 19 3H14.82C14.4 1.84 13.3 1 12 1S9.6 1.84 9.18 3H5C3.9 3 3 3.9 3 5V19C3 20.11 3.9 21 5 21H11.11C12.37 22.24 14.09 23 16 23C19.87 23 23 19.87 23 16C23 14.09 22.24 12.37 21 11.11M12 3C12.55 3 13 3.45 13 4S12.55 5 12 5 11 4.55 11 4 11.45 3 12 3M5 19V5H7V7H17V5H19V9.68C18.09 9.25 17.08 9 16 9H7V11H11.1C10.5 11.57 10.04 12.25 9.68 13H7V15H9.08C9.03 15.33 9 15.66 9 16C9 17.08 9.25 18.09 9.68 19H5M16 21C13.24 21 11 18.76 11 16S13.24 11 16 11 21 13.24 21 16 18.76 21 16 21M16.5 16.25L19.36 17.94L18.61 19.16L15 17V12H16.5V16.25Z"
                    />
                  </svg>
                  <!-- </span> -->
                  <!-- <span
                    v-if="menu.icon == 'QrCodeIcon'"
                    class="material-icons-outlined ml-1"
                  > -->
                  <svg
                    v-if="menu.icon == 'QrCodeIcon'"
                    style="width: 24px; height: 24px; color: #b7b7b7"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M4,4H10V10H4V4M20,4V10H14V4H20M14,15H16V13H14V11H16V13H18V11H20V13H18V15H20V18H18V20H16V18H13V20H11V16H14V15M16,15V18H18V15H16M4,20V14H10V20H4M6,6V8H8V6H6M16,6V8H18V6H16M6,16V18H8V16H6M4,11H6V13H4V11M9,11H13V15H11V13H9V11M11,6H13V10H11V6M2,2V6H0V2A2,2 0 0,1 2,0H6V2H2M22,0A2,2 0 0,1 24,2V6H22V2H18V0H22M2,18V22H6V24H2A2,2 0 0,1 0,22V18H2M22,22V18H24V22A2,2 0 0,1 22,24H18V22H22Z"
                    />
                  </svg>
                  <!-- </span> -->
                  <component :is="menu.icon" />
                </div>
                <div class="side-menu__title mr-2">
                  {{ i18n(`menu.${menu.title}`) }}
                  <span
                    class="bg-theme-24 text-white font-bold rounded-full mx-2 px-1.5"
                    v-if="menu.title == 'Orders' && ordersBadge > 0"
                    >{{ ordersBadge }}</span
                  >
                  <span
                    class="bg-theme-24 text-white font-bold rounded-full mx-2 px-1.5"
                    v-if="menu.title == 'service' && newServicesCount > 0"
                    >{{ newServicesCount }}</span
                  >
                  <span
                    class="bg-theme-24 text-white font-bold rounded-full mx-2 px-1.5"
                    v-if="
                      menu.title == 'maintenance' && newMaintenancesCount > 0
                    "
                    >{{ newMaintenancesCount }}</span
                  >
                  <span
                    class="bg-theme-24 text-white font-bold rounded-full mx-2 px-1.5"
                    v-if="menu.title == 'Complaints' && newComplaintsCount > 0"
                    >{{ newComplaintsCount }}</span
                  >
                  <div
                    v-if="menu.subMenu"
                    class="side-menu__sub-icon"
                    :class="{ 'transform rotate-180': menu.activeDropdown }"
                  >
                    <ChevronDownIcon />
                  </div>
                </div>
              </SideMenuTooltip>
              <!-- BEGIN: Second Child -->
              <transition @enter="enter" @leave="leave">
                <ul v-if="menu.subMenu && menu.activeDropdown">
                  <li
                    v-for="(subMenu, subMenuKey) in menu.subMenu"
                    :key="subMenuKey"
                  >
                    <SideMenuTooltip
                      tag="a"
                      :content="subMenu.title"
                      :href="
                        subMenu.subMenu
                          ? 'javascript:;'
                          : router.resolve({ name: subMenu.pageName }).path
                      "
                      class="side-menu"
                      :class="{ 'side-menu--active': subMenu.active }"
                      @click="linkTo(subMenu, router, $event)"
                    >
                      <div class="side-menu__icon">
                        <ActivityIcon />
                      </div>
                      <div class="side-menu__title">
                        {{ subMenu.title }}
                        <div
                          v-if="subMenu.subMenu"
                          class="side-menu__sub-icon"
                          :class="{
                            'transform rotate-180': subMenu.activeDropdown
                          }"
                        >
                          <ChevronDownIcon />
                        </div>
                      </div>
                    </SideMenuTooltip>
                    <!-- BEGIN: Third Child -->
                    <transition @enter="enter" @leave="leave">
                      <ul v-if="subMenu.subMenu && subMenu.activeDropdown">
                        <li
                          v-for="(
                            lastSubMenu, lastSubMenuKey
                          ) in subMenu.subMenu"
                          :key="lastSubMenuKey"
                        >
                          <SideMenuTooltip
                            tag="a"
                            :content="lastSubMenu.title"
                            :href="
                              lastSubMenu.subMenu
                                ? 'javascript:;'
                                : router.resolve({ name: lastSubMenu.pageName })
                                    .path
                            "
                            class="side-menu"
                            :class="{ 'side-menu--active': lastSubMenu.active }"
                            @click="linkTo(lastSubMenu, router, $event)"
                          >
                            <div class="side-menu__icon">
                              <ZapIcon />
                            </div>
                            <div class="side-menu__title">
                              {{ lastSubMenu.title }}
                            </div>
                          </SideMenuTooltip>
                        </li>
                      </ul>
                    </transition>
                    <!-- END: Third Child -->
                  </li>
                </ul>
              </transition>
              <!-- END: Second Child -->
            </li>
          </template>
          <!-- END: First Child -->
        </ul>
        <div class="runProf">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/home-food-plus.appspot.com/o/admin%2Fpublic%2Frunprof.svg?alt=media&token=92e71269-efea-4a44-b024-80f6b5c5730a"
            alt=""
          />
        </div>
      </nav>
      <!-- END: Side Menu -->

      <!-- BEGIN: Content -->
      <div class="content">
        <TopBar />
        <router-view />
      </div>
      <!-- END: Content -->
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '@/store'
import { helper as $h } from '@/utils/helper'
import TopBar from '@/components/top-bar/Main.vue'
import MobileMenu from '@/components/mobile-menu/Main.vue'
// import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import SideMenuTooltip from '@/components/side-menu-tooltip/Main.vue'
import { linkTo, nestedMenu, enter, leave } from './index'
import { mapActions, mapGetters } from 'vuex'
import PermissionChecker from '@/security/permission-checker'

export default defineComponent({
  components: {
    TopBar,
    MobileMenu,
    // DarkModeSwitcher,
    SideMenuTooltip
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const darkMode = computed(() => store.state.main.darkMode)
    const formattedMenu = ref([])
    const sideMenu = computed(() =>
      nestedMenu(store.state.sideMenu.menu, route)
    )

    watch(
      computed(() => route.path),
      () => {
        formattedMenu.value = $h.toRaw(sideMenu.value)
      }
    )

    onMounted(() => {
      cash('body')
        .removeClass('error-page')
        .removeClass('login')
        .removeClass('login-rtl')
        .addClass('main')
      formattedMenu.value = $h.toRaw(sideMenu.value)
    })

    return {
      formattedMenu,
      darkMode,
      router,
      linkTo,
      enter,
      leave
    }
  },
  computed: {
    ...mapGetters({
      // ordersBadge: 'realtime/ordersBadge',
      // productsBadge: 'realtime/productsBadge',
      // requestsBadge: 'realtime/requestsBadge',
      // listenerLoading: 'realtime/loading',
      // newComplaintsCount: 'complaintStore/newComplaintsCount',
      // newServicesCount: 'service/list/newServicesCount',
      // newMaintenancesCount: 'maintenance/list/newMaintenancesCount',
      currentUser: 'auth/currentUser'
    })
  },
  async created() {
    // this.doRunCurrentUserChangedListener()
    // this.doRunListenerOnNewMaintenance()
    // this.doRunListenerOnNewServices()
    // this.doRunListenerOnNewComplaints()
  },
  methods: {
    ...mapActions({
      // doRunListenerOnNewComplaints:
      //   'complaintStore/doRunListenerOnNewComplaints',
      // doRunListenerOnNewMaintenance:
      //   'maintenance/list/doRunListenerOnNewMaintenance',
      // doRunListenerOnNewServices: 'service/list/doRunListenerOnNewServices',
      // doRunCurrentUserChangedListener: 'auth/doRunCurrentUserChangedListener'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    hasPermissionToRead(permission) {
      return new PermissionChecker(this.currentUser).match(permission)
    }
  }
})
</script>

<style lang="scss" >
.runProf {
  position: absolute;
  // margin-bottom: auto !important;
  left: 50%;
  transform: translateX(-50%);
  @media only screen and (max-width: 1280px) {
    width: 80px;
    // left: -1%;
    // display: none;
  }
}

// [dir='rtl'] .runProf {
//   position: absolute;
//   bottom: 0%;
//   right: 5% !important;
//   @media only screen and (max-width: 1280px) {
//     width: 80px;
//     right: 1% !important;
//     // display: none;
//   }
//   @media only screen and (max-width: 768px) {
//     width: 80px;
//     left: 87% !important;
//     // display: none;
//   }
// }

[dir='rtl'] .side-nav > ul > li > .side-menu.side-menu--active:before {
  content: '';
  transform: rotate(180deg) scale(1.04) !important;
  right: unset !important;
  left: 0 !important;
}

[dir='rtl'] .side-nav > ul > li > .side-menu.side-menu--active:after {
  content: '';
  transform: rotate(270deg) scale(1.04) !important;
  right: unset !important;
  left: 0 !important;
}

[dir='rtl']
  .side-nav
  > ul
  > li
  > .side-menu.side-menu--active
  .side-menu__icon:before {
  content: '';
  right: unset !important;
  left: 0 !important;
}

[dir='rtl'] .side-nav .side-menu {
  padding-right: 1.25rem !important;
}

#logo {
  font-weight: 900 !important;
  font-size: 14px !important;
}
</style>
